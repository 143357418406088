<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <!-- Brand logo-->
    <b-link class="brand-logo">
      <dark-logo v-if="skin !== 'dark'" />
      <light-logo v-else />
    </b-link>
    <!-- /Brand logo-->

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('page.misc.not-found') }} 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          {{ $t('page.misc.oops') }}
        </p>

        <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{path:'/'}"
        >
          {{ $t('page.misc.back-to-home') }}
        </b-button>

        <!-- image -->
        <b-img
            fluid
            :src="imgUrl"
            alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import DarkLogo from '@core/layouts/components/Logo.vue'
import LightLogo from '@core/layouts/components/LogoLight.vue'
import store from '@/store/index'

export default {
  components: {
    DarkLogo,
    LightLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      skin: this.$store.state.appConfig.layout.skin,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
